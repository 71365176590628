import { PropsWithChildren, useMemo, useState } from 'react';

import Modal from '../components/modals/modal-core';
import { track, TrackingType } from '../tracking/ga';

export const useModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const ModalComponent = useMemo(
    () =>
      ({ children, gaPlace }: PropsWithChildren<any>) =>
        isModalOpen ? (
          <Modal
            onClose={() => {
              if (gaPlace === 'payment') {
                track(
                  'event',
                  TrackingType.Category.paymentPage,
                  TrackingType.Actions.click,
                  TrackingType.Labels.close,
                );
              }
              setModalOpen(false);
            }}
          >
            {children}
          </Modal>
        ) : null,
    [setModalOpen, isModalOpen],
  );

  return {
    // FIXME: update props type when using this hook
    Modal: ModalComponent,
    openModal: () => setModalOpen(true),
    closeModal: () => setModalOpen(false),
    isModalOpen,
  };
};
