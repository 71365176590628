import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
`;
export const Content = styled.div<{ bg?: 'primary' | 'secondary' }>`
  display: grid;
  flex: 1;
  row-gap: 2rem;
  place-items: center;
  padding: 2rem;
  border-radius: 1rem;
  background-color: ${({ theme, bg }) => (bg ? theme.palette.background[bg] : 'none')};
  width: 444px;
`;

export const MSWord = styled.div`
  display: grid;
  place-items: center;
  background: ${({ theme }) => theme.palette.background.secondary};
  border-radius: 50%;
  width: 11.25rem;
  height: 11.25rem;
`;

export const Check = styled(MSWord)<{ bg?: 'error' }>`
  width: 8rem;
  height: 8rem;
  color: ${({ theme, bg }) => theme.palette[bg || 'primary'].main};
  background: ${({ theme, bg }) => theme.palette[bg || 'primary'].light};
`;

export const Headline = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: 500;
`;

export const Text = styled.p<{ fontWeight?: string | number; color?: 'main' | 'secondary' }>`
  font-size: ${({ theme }) => theme.fontSize.small};
  text-align: center;
  line-height: 1.5rem;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: ${({ theme, color }) => theme.palette.text[color || 'secondary']};
`;
