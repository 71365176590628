import { inspect } from '@xstate/inspect';
import ReactDOM from 'react-dom';

import App from './app';
import Providers from './providers';
import { startPreviewService } from './state/preview.machine';
import { initGoogleAnalytics } from './tracking/ga';
import { isDevelopment } from './util/helpers';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_XSTATE) {
  inspect({ iframe: false });
}

startPreviewService();

if (
  !window?.document.location.search.includes(process.env.REACT_APP_DEMO_PARAM as string) ||
  (isDevelopment && process.env.REACT_APP_GA)
) {
  initGoogleAnalytics();
}

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.querySelector('#root'),
);
