import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ErrorDisplay } from './components/error-display';

import { DocumentProvider } from './context/document-context';
import { ModalProvider } from './context/modal-context';
import { ToasterProvider } from './context/toaster-context';
import { theme } from './styles/default.theme';

const queryClient = new QueryClient();

const Providers: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary
            //@ts-expect-error
            FallbackComponent={ErrorDisplay}
            onReset={() => (document.location.href = '/')}
          >
            <DocumentProvider>
              <ToasterProvider>
                <ModalProvider>{children}</ModalProvider>
              </ToasterProvider>
            </DocumentProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default Providers;
