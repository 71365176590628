import * as cldrSegmentation from 'cldr-segmentation';
import { useState } from 'react';
import { ContentEditableEvent } from 'react-contenteditable';
import { ErrorBoundary } from 'react-error-boundary';
import sanitizeHtml from 'sanitize-html';
import stripHtml from 'string-strip-html';

import { ErrorDisplay } from '../../components/error-display';
import * as s from './segmenter-test-page.styles';

const PLACEHOLDER = 'Start typing or paste your text here...';

export const SegmenterTestPage: React.FC = () => {
  const [text, setText] = useState(PLACEHOLDER);
  const [segments, setSegments] = useState([] as string[]);

  const onFocus = () => {
    if (text.trim() === PLACEHOLDER) {
      setText('');
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (event.target.innerHTML.trim() === '') {
      setText(PLACEHOLDER);
    }
  };

  const onChange = (event: ContentEditableEvent) => {
    setText(event.target.value);

    const text = event.target.value
      .replaceAll('<br>', '\n')
      .replaceAll('</div>', '\n')
      .replaceAll('<div>', '');
    const segmentedText = cldrSegmentation
      .sentenceSplit(stripHtml(text).result, cldrSegmentation.suppressions.en)
      .filter((segment) => segment.trim().length > 0);

    setSegments(segmentedText);
  };

  return (
    //@ts-expect-error
    <ErrorBoundary FallbackComponent={ErrorDisplay}>
      <s.contentEditable
        html={text}
        onPaste={onPaste}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
      {segments && segments.length > 0 && (
        <s.segments>
          {segments.map((segment, index) => (
            <s.segment key={index}>{segment}</s.segment>
          ))}
        </s.segments>
      )}
    </ErrorBoundary>
  );
};

const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
  event.preventDefault();
  const text = sanitizeHtml(event.clipboardData.getData('text/html'), {
    allowedTags: ['p', 'br'],
  });
  document.execCommand('insertHTML', false, text);
};
