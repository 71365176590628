import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import DevelopmentPanel from './components/_development-panel.tsx';
import ClearButton from './components/clear-button';
import { Environment } from './components/environment';
import { Logo } from './components/logo';
import UploadButton from './components/upload-button';
import { useResetEditor } from './hooks/use-reset-editor';
import { Footer } from './layout/footer';
import Layout from './layout/layout';
import MainPage from './routes/main-page/main-page';
import { SegmenterTestPage } from './routes/segmenter-test-page/segmenter-test-page';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorDisplay } from './components/error-display';

const App: React.FC = () => {
  const { reset } = useResetEditor({ withConfirm: false });

  return (
    <Layout
      header={
        <>
          <LogoWrapper onClick={reset}>
            <Logo />
          </LogoWrapper>
          <Actions>
            <ClearButton />
            <UploadButton />
          </Actions>
        </>
      }
      footer={<Footer />}
    >
      {/*@ts-expect-error*/}
      <ErrorBoundary onReset={reset} FallbackComponent={ErrorDisplay}>
        <Switch>
          <Route path="/segmenter" component={SegmenterTestPage} />
          <Route path="/:sessionId?" component={MainPage} />
        </Switch>
      </ErrorBoundary>
      <Environment />
      <DevelopmentPanel />
    </Layout>
  );
};

export default App;

const Actions = styled.div`
  display: flex;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;
