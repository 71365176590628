/* eslint-disable camelcase */
/* eslint-disable unicorn/prefer-date-now */
/* eslint-disable prefer-rest-params */

import { loadScript } from '../util/load-script';
import { isDevelopment } from '../util/helpers';
import { logToApm } from '../util/log-error';
import * as TrackingType from './tracking-types';

declare global {
  interface Window {
    ga: any;
  }
}

function getGa() {
  window.ga =
    //@ts-ignore
    window.ga ||
    function () {
      //@ts-ignore
      (ga.q = ga.q || []).push(arguments);
    };
  //@ts-ignore
  ga.l = +new Date();
  return window.ga;
}

export function initGoogleAnalytics() {
  loadScript(`https://www.google-analytics.com/analytics${isDevelopment ? '_debug' : ''}.js`);
  const ga = getGa();
  ga('create', process.env.REACT_APP_GA_TRACKING_ID, {
    storage: 'none',
  });
  ga('set', 'anonymizeIp', true);
}

type EventType = [
  hitType: 'event',
  category: TrackingType.Category,
  action: TrackingType.Actions,
  label?: TrackingType.Labels | string,
  value?: any,
];
type PageView = [htType: 'pageView', page?: string];

type TrackArgs = EventType | PageView;

type TrackParameter = [param: string, data: string | Record<string, string>];

export function setTrackParameter(...args: TrackParameter) {
  const ga = getGa();
  ga('set', ...args);
}

export function track(...args: TrackArgs) {
  const ga = getGa();
  if (!ga) {
    logToApm('google analytics is missing in window');
    return;
  }
  ga('send', ...args);
}

export * as TrackingType from './tracking-types';
