import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  HubbleSuggestion,
  Sentence as SentenceType,
  usePreviewMachine,
} from '../../../state/preview.machine';

interface Props {
  text: string;
  sessionId: string;
}

/**
 * Renders the preivew text from a document as a series of sentences with suggestions in the sentences
 * On first render the previewMachine is initialized with the preview text and sessionId
 */
export const Preview: React.FC<Props> = ({ text, sessionId }) => {
  const [state, send] = usePreviewMachine();
  useEffect(() => {
    send({ type: 'PREVIEW_CLEAR' });
    send({ type: 'INITIALIZE_PREVIEW_TEXT', text, sessionId });
  }, [send, text, sessionId]);

  const sentencesByNewlines = splitByNewlines(state.context.sentences);

  return (
    <TextContainer>
      {sentencesByNewlines.map((line, index) => (
        <p key={index}>
          {line.map((sentence, index) => (
            <Sentence
              sentence={sentence}
              key={sentence.hash + index}
              selectedSuggestionId={state.context.selectedSuggestionId}
              onSuggestionClick={(suggestionId: string) => () => {
                // console.log(ev);
                send({ type: 'SELECT_SUGGESTION', suggestionId });
              }}
            />
          ))}
        </p>
      ))}
    </TextContainer>
  );
};

/**
 * splits sentences into lines (or "paragraphs") based on \n characters.
 * Returns a double-array, where each item in the top level array is a list of sentences in that line
 */
const splitByNewlines = (sentences: SentenceType[]): SentenceType[][] => {
  const lines: SentenceType[][] = [[]];
  for (const sentence of sentences) {
    // always add the current sentence to the last available line
    lines[lines.length - 1].push(sentence);
    // create a new line if the sentence contains a newline character '\n'.
    // ! We're banking on newlines always be at the end of sentences and not in the middle of them.
    // ! That is true when using cldr-segmentation like we do now, but might not be true forever.
    if (sentence.text.includes('\n')) {
      lines.push([]);
    }
  }

  return lines;
};

interface SentenceProps {
  sentence: SentenceType;
  selectedSuggestionId?: string;
  onSuggestionClick: (suggestionId: string) => () => void;
}

/**
 * Renders a single sentence with possible suggestions.
 */
const Sentence: React.FC<SentenceProps> = ({
  sentence,
  selectedSuggestionId,
  onSuggestionClick,
}) => {
  // just render a break if the sentence is just a newline. This happens when sentences ends with multiple newlines eg. "hello world.\n\n"
  if (sentence.text === '\n') {
    return <br />;
  }
  // If we don't have any suggestions it's easy to just render the sentence bare
  if (!sentence.suggestions?.length || sentence.hideSuggestions) {
    return <span>{sentence.text}</span>;
  }
  const sentenceSplits = splitSentenceBySuggestions(sentence);
  return (
    <span>
      {sentenceSplits.map((split) => {
        // See splitSentenceBySuggestions below for structure
        if (typeof split === 'string') {
          return split;
        }
        return (
          <Suggestion
            {...split}
            selected={selectedSuggestionId === split.suggestion.id}
            key={split.suggestion.id}
            onClick={onSuggestionClick(split.suggestion.id)}
          />
        );
      })}
    </span>
  );
};

/**
 * Splits a sentence with suggestions into multiple substrings alternating between
 * "bare" text and text with suggestion and "bare" text again etc.
 * The split is represented with bare text as plain strings and text with suggestions as an object with { text: string; suggestion: HubbleSuggestion }
 */
const splitSentenceBySuggestions = (
  sentence: SentenceType,
): (string | { text: string; suggestion: HubbleSuggestion })[] => {
  /*
  positions is a list of character indexes where suggestions starts and stops.
  So in the sentence "hello world get on." where "world" and "on" has a suggestion each,
  the positions would be at "w", "d", "o" and "n" ie. [6, 10, 16, 17]
  */
  const positions = sentence.suggestions?.flatMap(({ start, end }) => [start, end]) || [];
  /*
  splits the text into parts with and without suggestions. The example above would result in:
  ["hello ", "world", " get ", "on", "."]
    BARE      SUG      BARE     SUG   BARE

  In case of a suggestion at the beginning of the sentence, the first split will be an empty string.
  Eg. with a suggestion for "hello world" above it would result in:
  ["",   "hello world", " get ", "on", "."]
    BARE  SUG            BARE     SUG   BARE

  So we always know that every second split is a suggestion.
  Whole algorithm adapted from https://stackoverflow.com/a/61397091
  */
  const splits = [0, ...positions].map((position, index, allPositions) => {
    const startPosition = position;
    const endPosition = allPositions[index + 1];
    const splitText = sentence.text.slice(startPosition, endPosition);

    // even positions are bare text...
    if (!(index % 2)) {
      return splitText;
    }
    // ... uneven positions are text with suggestions
    return {
      text: splitText,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- at this point we know the suggestion exists, otherwise we wouldn't have the position in the first place
      suggestion: sentence.suggestions!.find(
        ({ start, end }) => start === startPosition && end === endPosition,
      )!,
    };
  });
  return splits;
};

interface SuggestionProps {
  text: string;
  suggestion: HubbleSuggestion;
  selected?: boolean;
  onClick: () => void;
}

/**
 * Renders a suggestion. can be highlighted
 */
const Suggestion: React.FC<SuggestionProps> = ({ text, suggestion, selected, onClick }) => {
  const suggestionReference = useRef<HTMLSpanElement>();
  useEffect(() => {
    if (selected) {
      suggestionReference.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [selected]);

  return (
    //@ts-ignore
    <SuggestionWrapper ref={suggestionReference} onClick={onClick}>
      {text && <Replaced selected={selected}>{text}</Replaced>}
      {suggestion.replacement && (
        <Replacement selected={selected}>{suggestion.replacement}</Replacement>
      )}
    </SuggestionWrapper>
  );
};
const SuggestionWrapper = styled.span`
  cursor: pointer;
  & > span:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  & > span:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  scroll-behavior: smooth;
`;

const Replaced = styled.span<{ selected?: boolean }>`
  padding: 2px;
  color: rgb(204, 0, 68);
  text-decoration: line-through;
  background-color: ${({ selected }) => (selected ? '#FAE6ED' : 'initial')};
`;
const Replacement = styled.span<{ selected?: boolean }>`
  padding: 2px;
  color: rgb(0, 96, 255);
  background-color: ${({ selected }) => (selected ? '#E6F0FF' : 'initial')};
`;

const TextContainer = styled.main`
  &,
  & * {
    font-family: IBM Plex Serif;
  }
`;
