import { Headline, Text, Check } from './styles';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import Icon from '../../icon';
import { track, TrackingType } from '../../../tracking/ga';

type Props = {
  downloadUrl: string | null;
  downloadExpired?: boolean;
  downloadAvailableDays?: number;
};

export const DocumentDone: React.FC<Props> = (props) => {
  return (
    <>
      <Headline>Payment confirmed</Headline>
      <Check>
        <Icon icon={faCheck} size="3x" />
      </Check>
      {props.downloadExpired ? (
        <Text>The link is expired</Text>
      ) : (
        <Text>
          Your download should start automatically. <br />{' '}
          {props.downloadUrl && (
            <>
              If not, please{' '}
              <a
                href={props.downloadUrl}
                download
                onClick={() =>
                  track(
                    'event',
                    TrackingType.Category.paymentPage,
                    TrackingType.Actions.manuallyFileDownload,
                  )
                }
              >
                click here to download.
              </a>
            </>
          )}
        </Text>
      )}
      <Text fontWeight="500" color="main">
        Your file is available for {props.downloadAvailableDays}{' '}
        {props.downloadAvailableDays && props.downloadAvailableDays > 1 ? 'days' : 'day'}.
      </Text>
      <Text>We have emailed you the payment receipt as well as a link to your document.</Text>
    </>
  );
};
