import { useHistory } from 'react-router-dom';

import { useDocument } from '../context/document-context';
import { usePreviewMachine } from '../state/preview.machine';

type Props = {
  withConfirm?: boolean;
  confirmMessage?: string;
};

export const useResetEditor = ({ withConfirm, confirmMessage }: Props) => {
  const { push, location } = useHistory();
  const { send: sendDocumentMachine } = useDocument();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, sendPreviewMachine] = usePreviewMachine();

  const reset = () => {
    const userConfirmed = withConfirm
      ? window.confirm(confirmMessage || "Are you sure you want clear? This action can't be undone")
      : true;
    if (userConfirmed) {
      sendDocumentMachine({ type: 'UPLOAD_CLEAR' });
      sendPreviewMachine({ type: 'PREVIEW_CLEAR' });
      push(`/${location.search}`);
    }
  };

  return { reset };
};
